<template>
    <div class='acc-container'>
        <div v-if="izd !== 'vform'" class="img-container" @click="openModal('photo_stvorka')">
          <svg width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M448 80c8.8 0 16 7.2 16 16l0 319.8-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3L48 96c0-8.8 7.2-16 16-16l384 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
          <span>Фото, <br> как сделать замер <br> на створку</span>
        </div>
        <div v-if="izd !== 'plisse'" class="img-container" @click="openModal('photo_proem')">
            <svg width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M448 80c8.8 0 16 7.2 16 16l0 319.8-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3L48 96c0-8.8 7.2-16 16-16l384 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
            <span>Фото, <br> как сделать замер <br> на проем</span>
          </div>
          <div v-if="video[izd]"  class="img-container" @click="openModal('video')">
         <svg width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/></svg>
          <span>Видео, <br> как сделать замер</span>
        </div>
        <modal-component v-if="isModal" @updateParent="isModal = $event.isModal; isContent = false;"> 
            <div class="acc-modal-content">
                <img v-show="isContent"  @load="isContent = true" v-if="modalContent === 'photo_proem'" src="https://www.foroom.ru/img-new/constructor/Sizes/measure/svobodnovisywie.jpg" alt="Замер на проем">
                <img v-show="isContent"  @load="isContent = true" v-else-if="modalContent === 'photo_stvorka'" src="https://www.foroom.ru/img-new/constructor/Sizes/measure/zamer_bez_glubiny_shtapika_integra.jpg" alt="Замер на створку">
                <iframe v-show="isContent"  @load="isContent = true" v-else :src="video[izd]" frameborder="0"></iframe>
                <LoaderComponent :isLoader="!isContent"/>
            </div>
           
            
       
            
        </modal-component>
    </div>
</template>

<script>
import './PhotoModal.css';
import ModalComponent from "@/components/UI/Modal/ModalComponent.vue";
import LoaderComponent from "@/components/UI/LoaderComponent/LoaderComponent.vue";

export default {
  components: { ModalComponent, LoaderComponent },
    name: 'PhotoModal',
    // component:{ModalComponent},
    data(){
        return{
            isModal: false,
            modalContent: 'photo',
            isContent: false,
            video:{
                rolo: 'https://www.youtube.com/embed/WYFDe05ovyg',
                gform: 'https://www.youtube.com/embed/ipbXSgRlzeE',
                vform: 'https://www.youtube.com/embed/a-bSSh2ujjY',
            }
        }
    },
    methods:{
        openModal(modalContent){
            this.isModal = true;
            this.modalContent = modalContent;
        }
    },
    computed: {
        izd() {   
         return this.$store.getters.izd;
        },
    },
  
}
</script>