<template>
    <div class="advantage-item">
        <img :src="require(`@/assets/images/advantages/${img}`)" class="advantage-item__img" alt="">
        <div class="advantage-item__text">{{text}}</div>
    </div>
</template>

<script>
    import './advantage-item.css';
    export default{
        name: 'AdvantageItem',
        props: ['text', 'img'],
    }
</script>