<template>
    <section>
        <wrapper-component :id="'howWeWork'">
            <TitleComponent :title="title" :customClass="'working__title'" />
            <ListItem v-for="(item, index) in list" :key="index" :color="item.color" :listNumber="index + 1" :text="item.text"/>
        </wrapper-component>
    </section>
</template>

<script>
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
import ListItem from "./ListItem/ListItem";
import TitleComponent from "@/components/UI/Title/TitleComponent.vue";
    export default{
        name: 'HowWeWork',
        props: ['sectionName', 'title'],
        components: {WrapperComponent, ListItem, TitleComponent},
        data(){
            return{
                    list: [
                        {text:'Оставьте заявку на сайте или позвоните нам. Мы оперативно свяжемся с Вами, чтобы обсудить детали будущего изделия и согласовать удобное время для визита нашего специалиста.', color: '#6b2b52'}, 
                        {text:'Эксперт приедет к Вам с разнообразными образцами материалов, окажет профессиональную консультацию, подберет изделие, которое подойдет именно Вам и оформит договор на месте. Всё для Вашего удобства!', color: '#5a7410'},
                        {text:'Ваш заказ оперативно отправится на производство, где опытные мастера создадут изделие по вашим параметрам.', color: '#18637b'},
                        {text:'По завершении производства изделия мы оперативно свяжемся с Вами, чтобы согласовать удобную дату и комфортное время доставки и установки.', color: '#c31b1b'},
                        {text:'В согласованный день к Вам приедет монтажная бригада и оперативно установит изделия у Вас дома. Вам останется лишь убедиться в качестве выполненных работ и подписать акт приемки услуг.', color: '#9dc418'}
                    ]
            }
        }
    }
</script>

<style>
</style>