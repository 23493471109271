<template>
    <div :class="'container ' + classes " :id="id">
        <slot></slot>
    </div>
</template>

<script>
export default{
    name: 'WrapperComponent',
    props: ['classes', 'id'],
    
}
</script>
<style>
.container{
    padding: 20px;
    max-width: 1500px;
    margin: 0 auto;
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .container{
        padding: 30px 60px;
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 1025px) {
    .container{
        padding: 20px;
        /* margin-bottom: 30px; */
    }
}
</style>