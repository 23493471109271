<template>
    <div class="list-item">
        <div class="list-item__number" :style="'border-color:' + color + ';'">{{ listNumber }}</div>
        <div class="list-item__text standart-text">{{text}}</div>
    </div>
</template>

<script>
    import './list-item.css';
    export default{
        name: 'ListItem',
        props: ['listNumber', 'text', 'color'],
    }
</script>