<template>
  <div class="modalWrapper" @click="closeTrigger($event.target)">
    <div class="modal">
      <button class="modal__exit"></button>
      <slot @closeFunc="closeFunc()"></slot>
    </div>
  </div>
</template>

<script>
import "./modal.css";
export default {
  name: "ModalComponent",
  emits: ["isModal"],
 
  methods: {
    closeTrigger(el) {
      if (el.classList.contains("modalWrapper") || el.classList.contains("modal__exit")) {
        this.$emit("updateParent", { isModal: false });
      }
    },
  },
};
</script>
