<template>
    <nav class="nav">
        <ul class="nav__list">
            <li v-for="item of list" :key="item.link" :class="item.classes + ' ' + 'nav__item'"><a v-if="item.anchor" :href="'#'+item.anchor">{{ item.text }}</a><span class="nav__dropdown-toggler"   @mouseover="isDropdown = true" v-else>
                {{ item.text }}
                <DropDownListVue @updateParent="isDropdown = $event.isDropdown;" :isDropdown="isDropdown" :list="dropdownList" :classes="'nav__dropdown'"/>
                </span></li>
        </ul>

    </nav>
</template>
<script>
import DropDownListVue from '@/components/UI/DropDownList/DropDownList.vue'
    import './navigation.css'
    export default{
        name: 'NavigatonComponent',
        components: {DropDownListVue},
        props: ['list', 'dropdownList'],
        data() {
            return {
                isDropdown: false,
            }
        },
    }
</script>