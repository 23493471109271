<template>
    <div class="slide-container">
          <img 
           :src="`${require(`@/assets/images/slider-component/${izd}/${fullTablet ? '1024' : '500'}/${item.img}`)}`"
           :alt="'slide_' + (index + 1)"
           @click="changeVisible()"
           >
          <div @click="changeVisible()" v-if="item.text" :class="'slide-highlight ' + (isShowText ? 'active' : '')">
                  <span v-for="(textItem, i) of item.text" :key="i">
                      {{ textItem }}
                  </span>
          </div>
    
          
        </div>
</template>

<script>
    export default{
        name: 'SlideComponent',
        props: ['item', 'index', 'izd'],
        data(){
            return{
                isShowText: false,
            }
        },
        methods:{
            changeVisible(){
            if(!this.fullDesktop){
                this.isShowText = !this.isShowText;
            }
            }
        },
        computed:{
            fullTablet(){
            return this.$store.getters.fullTablet;
            },
            fullDesktop(){
            return this.$store.getters.fullDesktop;
            }
        }
    }
</script>