<template>
  <section :class="'slider-component'">
    <wrapper-component>
      <TitleComponent :customClass="className + ' ' + izd" :title="title"/>
      <swiper
      :modules="modules"
      :slides-per-view="1"
      :space-between="50"
      :loop="true"
      :navigation="fullDesktop"
      :pagination="fullDesktop ? false : { clickable: true }"
      >
      <swiper-slide v-for="(item, index) of sliderList" :key="index">
        <SlideComponent :item="item" :izd="izd" :index="index"/>
      </swiper-slide>
        
        
      </swiper>
    </wrapper-component>
  </section>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
// Import Swiper styles
import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';
  import './slider.css';
import TitleComponent from "@/components/UI/Title/TitleComponent.vue";
import SlideComponent from './SlideComponent/SlideComponent.vue';
export default {
  name: "HeroSliderComponent",
  components: {
    Swiper,
    SwiperSlide,
    WrapperComponent,
    TitleComponent,
    SlideComponent,
  },
  props: ['sliderList', 'title', 'className', 'izd'],

  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  computed:{
    fullDesktop(){
      return this.$store.getters.fullDesktop;
    },
  }
};
</script>
