export const dataModule = {
    state() {
        return {
            fullDesktop: false,
            fullTablet: false,
            counter: 0,
            izd: 'rolo',
        }
    },
    getters: {
        fullDesktop: s => s.fullDesktop,
        fullTablet: s => s.fullTablet,
        counter: s => s.counter,
        izd: s => s.izd
    },
    
    mutations: {
        setFullDesktop(state, payload) {
            state.fullDesktop = payload;
        },
        setFullTablet(state, payload) {
            state.fullTablet = payload;
        },
        setCounter(state, payload){
            state.counter = payload;
        },
        setIzd(state, payload){
            state.izd = payload;
        }
    },
    actions: {
        startListener({commit}){
            const mediaQueryDesktop = window.matchMedia("(min-width: 1025px)");
            const mediaQueryTablet= window.matchMedia("(min-width: 768px)");
            commit('setFullDesktop', mediaQueryDesktop.matches);
            commit('setFullTablet', mediaQueryTablet.matches);
            mediaQueryDesktop.addEventListener("change", ()=>{
                commit('setFullDesktop', mediaQueryDesktop.matches);
            });
            mediaQueryTablet.addEventListener("change", ()=>{
                commit('setFullTablet', mediaQueryTablet.matches);
            });
        },
        counter({commit}){
            let storageCounter = localStorage.getItem('counter');
            if(storageCounter){
                storageCounter - Number(storageCounter);
                const intervalId = setInterval(() => {
                    localStorage.setItem('counter', storageCounter);
                    commit('setCounter', storageCounter);
                    storageCounter -= 1;
                  if (storageCounter === 0) {
                      localStorage.removeItem('counter');
                      commit('setCounter', storageCounter);
                      clearInterval(intervalId);
                    }
                  }, 1000);
            }
           
        },
        setIzd({commit}, payload){
            commit('setIzd', payload)
        }
    }

}