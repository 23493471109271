<template>
    <div v-if="isLoader" class="loader">
        
    </div>
</template>

<script>
export default {
    name: 'LoaderComponent',
    props: ['isLoader']
}
</script>

<style>
/* HTML: <div class="loader"></div> */
.loader {
  width: 200px;
  aspect-ratio: 1;
  border-radius: 50%;
  -webkit-mask:linear-gradient(0deg,#000 55%,#0000 0) bottom/100% 18.18%;
  background:
   linear-gradient(#df771c 0 0) bottom/100% 0% no-repeat
   #ddd;
  animation: l8 2s infinite steps(7);
}
@keyframes l8 {
    100% {background-size:100% 115%}
}
</style>
