// import App from '@/App.vue';
// import RoloPage from '@/pages/RoloPage.vue';
import MainComponent from '@/components/Main/MainComponent.vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        component: MainComponent,
        props:{
            izd: 'rolo'
        }

    },
    {
        path: '/rolo',
        component: MainComponent,
        props:{
            izd: 'rolo'
        }
        
    },
    {
        path: '/gform',
        component: MainComponent,
        props:{
            izd: 'gform'
        }
        
    },
    {
        path: '/plisse',
        component: MainComponent,
        props:{
            izd: 'plisse'
        }
        
    },
    {
        path: '/vform',
        component: MainComponent,
        props:{
            izd: 'vform'
        }
        
    },
    {
        path: '/roma',
        component: MainComponent,
        props:{
            izd: 'roma'
        }
        
    },
    
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router