<template>
     <HeaderComponent/>
     <!-- <MainComponent/> -->
     <RouterView />
     <FooterComponent/>
</template>

<script>
import './app.css'
import '@/assets/styles/normalize.css'
import HeaderComponent from './components/Header/HeaderComponent.vue'
// import MainComponent from './components/Main/MainComponent.vue'
import FooterComponent from './components/Footer/FooterComponent.vue'


export default {
  name: 'App',
  props: ['izd'],
  components: {
    HeaderComponent,
    // MainComponent,
    FooterComponent
  },
  mounted(){
    this.$store.dispatch('startListener');
  }
}
</script>

<style>
  html{
    font-family: 'Circe';
  }

  body{
    background-color: #f5f5f5;
  }
</style>
