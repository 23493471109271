<template>
  <footer class="footer">
    <wrapper-component :classes="'footer__container'">
      <div class="footer__left-side">
        <ul class="footer__list">
          <li class="footer__list-item">
            <h2 class="footer__mini-title footer__title-icon footer__title-icon_rek">Реквизиты компании</h2>
            <p class="footer__text">ИП Алтынов Александр Владимирович, ИНН: 622802144916, ОГРНИП: 314623430200040</p>
          </li>
          <li class="footer__list-item">
            <h2 class="footer__mini-title footer__title-icon footer__title-icon_contacts">Контакты</h2>
            <a href="tel:+74912256806" class="footer__text">+7 (4912) 256-806</a>,
            <a href="tel:+79206330009" class="footer__text">+7 (920) 633-00-09</a>
            <div class="footer__social">
              <a href="https://t.me/SB_Decor" class="footer__social-item footer__social-item_tg"><img src="@/assets/images/footer/social/tg.png" alt="telegram"></a>
            <a href="viber://pa?chatURI=sb_decor" class="footer__social-item footer__social-item_viber"><img src="@/assets/images/footer/social/viber.png" alt="telegram"></a>
            <a href="https://vk.com/rznforoom" class="footer__social-item footer__social-item_vk"><img src="@/assets/images/footer/social/vk.png" alt="telegram"></a>
            <a href="https://ok.ru/rznforoom" class="footer__social-item footer__social-item_ok"><img src="@/assets/images/footer/social/ok.png" alt="telegram"></a>
            </div>
            <br>
            <span class="footer__text">Адрес: 390000, г. Рязань, ул. Маяковского, д. 49, пом. Н 17</span>
          </li>
          <li class="footer__list-item">
            <h2 class="footer__mini-title">Координаты</h2>
            <p class="footer__text">
              54.622842, 39.739207
            </p>
          </li>
          <li class="footer__list-item">
            <h2 class="footer__mini-title footer__title-icon footer__title-icon_graphik">График работы</h2>
            <p class="footer__text">
              Пн.-Пт.: 10.00-18.00, Сб.: 10.00-15.00, Вс.: выходной
            </p>
          </li>
        </ul>
        <div v-if="fullDesktop" class="footer__bottom-side">
          <h2 class="footer__mini-title footer__bottom-title">Сайт производителя систем foroom</h2>
        </div>
      </div>
      <div class="footer__right-side">
        <h2 class="footer__mini-title">Схема проезда</h2>
        <!-- <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A530c3b8e1a833ddbca741b18f8d214874fc874b9fb89115ae3012efcfd942e04&amp;source=constructor"
          frameborder="0"
          class="footer__map"
        ></iframe> -->

    <!-- <YandexMap 
      :coords="coords"
      :zoom="10"
      :settings="settings"    
    >
      <YandexMapMarker 
        :coords="coords" 
        marker-id="123" 
        hint-content="some hint" 
      />
    </YandexMap> -->

  <div class="map" id="map"></div>

      </div>
      <div v-if="!fullDesktop" class="footer__bottom-side">
        <h2 class="footer__mini-title footer__bottom-title">Сайт производителя систем foroom</h2>
      </div>
    </wrapper-component>
  </footer>
</template>

<script>
import WrapperComponent from "../Wrappers/WrapperComponent.vue";
import ymaps from 'ymaps';
// import  {YandexMap, YandexMapMarker}  from 'vue-yandex-maps';
// import { YandexMap, YandexMapDefaultSchemeLayer, YandexMapMarker } from 'vue-yandex-maps';
import "./footer.css";
export default {
  name: "FooterComponent",
  components: { WrapperComponent
    // , YandexMap, YandexMapMarker 
  },
  data() {
    return {
      footerData: [
        {
          title: "" 
        }
      ],
      apiKey: '',
      coords: [
      54.82896654088406,
      39.831893822753904,
      ],
      settings: {
        apiKey: 'fd261ce4-b879-4d4a-a897-bb15cf1371e0',
        suggestApiKey: '',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'  
      },
      markers: [
        {
          coordinates: [51.789682128109, 55.140428698122],
        },
        {
          coordinates: [54.76778893634, 57.108481458691],
        },
      ],
    };
  },
  mounted(){
    ymaps
  .load()
  .then(maps => {
    // const { YMapMarker} = ymaps; 
    const map = new maps.Map('map', {
      center: [54.622842, 39.739207],
      zoom: 15
    });


  const placemark = new maps.Placemark([54.622842, 39.739207,], {
            iconContent: `<img width='35' style="margin-left: -5px; margin-top: -6px;" src="${require(`@/assets/images/foroom_marker.svg`)}"/>`,
            hintContent: "Салон «СБ Декор»"
        }, {
            // Disabling the replacement of the conventional balloon with the balloon panel.
            balloonPanelMaxMapArea: 0,
            draggable: "true",
            preset: "islands#darkOrangeCircleIcon",
            openEmptyBalloon: true
        });

        placemark.events.add('balloonopen', function () {
        placemark.properties.set('balloonContent', 
                `<div class="balloon">
                <div class="balloon__body">
                <p class="shop-type"><b>Салон «СБ Декор»</b></p>
                <p class="shop-type">Представитель FOROOM</p>
                <p><span class="d-inline-block">Город:</span> Рязань</p>
                <p><span class="d-inline-block">Адрес:</span>ул. Маяковского, д. 49</p>
                <p><span>Телефон:</span> +7 920 633-00-09</p>
                <p>
                  <span>Время работы:</span>
                 Пн-Пт: с 10:00 по 18:00
                 <br>
                  Сб: с 10:00 по 15:00
                  <br>
                  Выходной: Вс
                </p>
              </div>
              <div class="col-4">
                <div class="balloon__img-container" id="shopImg" data-img="map_8a1099ffe584a2c540f0.jpg">
                  <img class="balloon__img" src="https://foroom.ru/img/images/map_items/map_8a1099ffe584a2c540f0.jpg"data-img="map_8a1099ffe584a2c540f0.jpg">
                </div>
              </div>
          </div>`);

    });

    map.geoObjects.add(placemark);
    

    
  })
  .catch(error => console.log('Failed to load Yandex Maps', error));
  },
  computed: {
    fullDesktop() {
      return this.$store.getters.fullDesktop;
    },
  },
};
</script>

<style>

  .map{
    width: 100%;
    max-width: 700px;
    height: 400px;
  }
  .balloon{
    display: flex;
    padding: 10px;
    width: 400px;
    height: 200px;
  }



  .balloon p{
    margin-right: 15px;
    margin-bottom: 5px;
    color: #3b3a49;
    font-style: normal;
    /* font-weight: 300; */
    letter-spacing: .2px;
  }

  .balloon img{
    width: 120px;
    height: 90px;
  }
</style>
