<template>
    <section class="form">
        <wrapper-component :classes="'form__container'">
            <TitleComponent :title="title" :customClass="'form__title'"/>
            <form @submit.prevent="sendMail()" class="form__content">
                <input :value="form.name" @change="form.name = $event.target.value"  placeholder="Имя" class="form__input" name="name" type="text">
                <input :value="form.phone" @change="telMask($event.target)" placeholder="Телефон" class="form__input" name="phone" type="phone">
                <input v-if="izd" type="hidden" name="izd" :value="izd ? izd : ''">
                <input class="form__input form__input_button" type="submit" value="Отправить">
            </form> 
        </wrapper-component>
    </section>
</template>

<script>
import TitleComponent from '@/components/UI/Title/TitleComponent.vue';
import './form.css';
import axios from 'axios';
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
    export default {
        name: 'FormComponent',
        props: ['sectionName', 'title', 'izd', 'clientMail', 'type', 'izdInfo'],
        components:{WrapperComponent, TitleComponent},
        data(){
            return{
                form:{
                    name: '',
                    phone: '',
                    izd: this.izd,
                },
            }
        },


        methods:{
            async multiSender(emailArray, mailBody){
                const errArray = [];
                for(let email of emailArray){
                    let result = await axios.post('https://api.foroom.ru/', {
                        auth: {},
                        data: {
                            email: email,
                            body: mailBody,
                            header: 'ЗАЯВКА С САЙТА FOROOM.SBDECOR.RU',
                        },
                        module: 'streamAdmin',
                        cmd: 'send_email',
                        key: process.env.VUE_APP_API_KEY
                    }); 

                    if((result.data && result.data.status !== 'OK') || result.statusText !== 'OK'){
                        errArray.push(result);
                    }
                }
                return errArray;
            },
            async sendMail(){
                if(this.counter > 0){
                    alert(`Для повторной отправки письма подождите ${this.counter} секунд!`);
                    return false;
                }
                const emailArray = this.clientMail;  
                const mailTemplates = {
                        measure : `<h2>Здравствуйте!</h2> <p>Вы получили заявку с сайта sbdecor.foroom.ru на замер.</p> <h3>Данные заказчика:</h3> <p>Имя: ${this.form.name}</p> <p>Телефон: ${this.form.phone}</p> `,
                        call: `<h2>Здравствуйте!</h2> <p>Вы получили заявку с сайта sbdecor.foroom.ru на обратный звонок.</p> <h3>Данные заказчика:</h3> <p>Имя: ${this.form.name}</p> <p>Телефон: ${this.form.phone}</p>`,
                        calculation: `Здравствуйте! Вы получили заявку с сайта sbdecor.foroom.ru на расчет стоимости изделия. <h3>Данные заказчика:</h3> <p>Имя: ${this.form.name}</p> <p>Телефон: ${this.form.phone}</p>`,
                }

                if(this.type === 'order'){
                    mailTemplates.order = `<div style="color:#333"><h2>Здравствуйте!</h2> <p>Вы получили заявку с сайта sbdecor.foroom.ru на приобретение товара.</p>  <h3>Данные заказа:</h3> <p>Система: ${this.izdInfo.izdName}</p> <p>Размер: ${this.izdInfo.izdSize}</p> <p>Материал: ${this.izdInfo.izdMaterial}</p> <p>Имя заказчика: ${this.form.name}</p> <p>Телефон: ${this.form.phone}</p></div>`;
              
                }

                const mailBody = mailTemplates[this.type];
                for(let item of Object.values(this.form)){
                    if(item.length < 3){
                        alert('Заполните все поля!');
                        return false;
                    }
                }

              
                const errArray = await this.multiSender(emailArray, mailBody);

                this.form.name = '';
                this.form.phone = '';
               
                
                if(this.type === 'order'){
                    this.$emit('closeModal', {isModal: false})
                }
                
                
                if(errArray.length < 1){
                    alert('Письмо отправлено успешно');
                    localStorage.setItem('counter', 10);
                    this.$store.dispatch('counter');
                }
                else{
                    console.log(errArray);
                    alert('Произошла ошибка');
                }
            },
            telMask(el){
                const pattern = /^((\+7|7|8)+([0-9]){10})$/;
                const value = el.value.replaceAll(/[()-\s]/g, '');
                if(el.name === 'phone' && !pattern.test(value)){
                    alert('Введите корректный номер')
                }
                else{
                    this.form[el.name] = value;
                }
            }
        },
        computed:{
            counter(){
                return this.$store.getters.counter;
            }
        }

    }
</script>


